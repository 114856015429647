$dark: #020202;
$gray: #595959;
$darkerGray: #101010;
$grey: #353535;
$lightGray: #9E9E9E;
$black: #020202;
$grayBackground: #f7f7f7;
$columnBackground: #f7f7f7;
$blue: #083d7c;
$green: #10ffdc;
$darkGray: #b7b7b7;
$facebook: #3b5998;
$lightBlue: #2196f3;
$yellow: #ffd959;
$cyan: #cdf8f9;
$google: #df4c38;
