@import './_colors';
@import './_breakpoints';

* {
	margin: 0;
}

.seo {
	// visibility: hidden;
	display: none;
}

body {
	margin: 0;
	font-size: 85% !important;
	font-family: 'Montserrat', sans-serif !important;
	.navbar-dark {
		background-color: $dark !important;
		.bg-light:hover {
			background-color: white !important;
		}
	}
	.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
		background-color: green !important;
	}

	.custom-checkbox .custom-control-input:checked:focus ~ .custom-control-label::before {
		box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 255, 0, 0.25);
	}
	.custom-checkbox .custom-control-input:focus ~ .custom-control-label::before {
		box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
	}
	.custom-checkbox .custom-control-input:active ~ .custom-control-label::before {
		background-color: #c8ffc8;
	}

	.text_normal {
		p {
			font-size: 1.2em;
		}
		.plan_card {
			p {
				font-size: 1em;
			}
		}
	}
	@media only screen and (max-width: $breakpoint-sm) and (max-width: $breakpoint-xl) {
		font-size: 0.8em !important;
		.display-4 {
			font-size: 2.7em;
		}
		h1 {
			font-size: 1.6em;
		}
		h4 {
			font-size: 1.05em !important;
		}
		h5 {
			font-size: 1.15em;
		}
		button {
			font-size: 0.8em !important;
		}
		.car_years {
			p {
				font-size: 0.9em !important;
			}
		}
		#news_section {
			li {
				font-size: 0.8em;
			}
		}
		.result_card {
			svg {
				width: 15px !important;
			}
		}

		.mt-sm {
			margin-top: 45px;
		}
	}

	.fixed-botton {
		z-index: 1;
	}

	#infopro_section {
		#search_bar {
			background-color: $black;
			border: 1px solid black;
			small {
				color: white;
				letter-spacing: 3px;
			}
			.selected {
				color: black;
				background-color: white;
				box-sizing: border-box;
				-moz-box-sizing: border-box;
				-webkit-box-sizing: border-box;
				border-bottom: 3px solid black;
				font-weight: bold;
			}
		}

		.inner_shadow {
			box-shadow: inset -12px 0 5px -5px rgba(0, 0, 0, 0.25) !important;
		}

		.first_col {
			width: 30px;
			left: 15px;
			min-height: 25px;
		}

		.second_col {
			left: 44px !important;
			width: 300px;
			min-width: 300px;
			max-width: 300px;
			min-height: 25px;
		}
		.third_col {
			left: 343px;
			min-width: 100px !important;
			max-width: 100px;
			height: 39px;
			min-height: 25px;
		}

		.fourth_col {
			left: 442px;
			min-width: 60px !important;
			max-width: 60px !important;
		}

		.fifth_col {
			padding-left: 513px;
			text-align: center;
		}
		.testeando {
			background-color: re;
		}

		.table-light {
			background: white;
			background-color: white;
		}

		#infopro_table {
			position: relative;
			-webkit-user-select: none !important; /* Safari */
			-moz-user-select: none !important; /* Firefox */
			-ms-user-select: none !important; /* IE10+/Edge */
			user-select: none !important; /* Standard */
			display: block;
			overflow-x: auto;
			white-space: nowrap;

			.infogrid-row {
				border-left: 0px;
			}

			.pinned_row {
				border-bottom: 2px solid black;
				td {
					border-bottom: 2px solid black;
				}
			}

			tbody {
				border-left: 0px;
			}

			th {
				min-width: 60px;
			}
			td {
				color: black;
				background-color: white;
				box-sizing: border-box;
				-moz-box-sizing: border-box;
				-webkit-box-sizing: border-box;
				border: 1px solid #ccc;
				padding: 0.5rem;
			}
			.infopro_svg {
				width: 15px;
			}
			.price_cell {
				min-width: 30px;
				span {
					visibility: hidden;
				}
			}
			.see_price {
				letter-spacing: 4px;
				font-size: 0.7em;
				font-weight: bold;
				padding-top: 8px;
			}
			.filled_cell {
				background: #ccc !important;
				border: 1px solid white;
			}
			.hovered {
				td {
					background-color: black;
					color: white;
				}
				.filled_cell {
					background: #444 !important;
				}
			}
			.viewed {
				td {
					background-color: $blue !important;
					color: white;
				}
				.filled_cell {
					background: $blue !important;
				}
				span {
					visibility: visible;
				}
			}
		}
	}

	#infopro_header {
		border-bottom: 1px solid $blue;
	}
	.infopro_logo {
		.square {
			background-color: $blue;
			padding: 2px 3px 2px 2px;
			color: white;
			margin-left: 3px;
			font-weight: bold;
		}
	}
	.nav-item {
		position: relative;
		ul {
			list-style: none;
			padding: 0;
		}
		.nav_submenu {
			background-color: white;
			position: absolute;
			z-index: 1000;
			min-width: 100%;

			a {
				color: black;
				text-decoration: none;

				li {
					border-bottom: 1px solid black;
					border-left: 1px solid black;
					border-right: 1px solid black;
					font-size: 0.8em;
					text-transform: uppercase;
					cursor: pointer;
				}
			}

			a {
				color: black;
				li:hover {
					background: $black;
					color: white;
				}
			}
		}
	}
	#loader_container,
	#app_container {
		min-height: 100vh;
	}

	.payment-pending-modal {
		position: absolute;
		z-index: 9999999;
		height: 100vh;
		width: 100vw;
		background-color: rgba(0, 0, 0, 0.8);
		top: 0;
	}

	.perfil {
		border-top: 1px solid black;
		font-size: 0.8em;
		text-transform: uppercase;
		cursor: pointer;
		padding-top: 4px;
		padding-bottom: 4px;
		background-color: white;
	}

	.perfil:hover {
		background-color: black;
		color: white;
	}

	.perfil-borde {
		border-left: 1px solid black;
		border-right: 1px solid black;
		border-bottom: 0px;
	}

	#enterprise_header,
	.response_section {
		ul {
			list-style: none;
			margin: 0;
			padding: 0;
			box-sizing: border-box;
			border-left: 5px solid $green;
			padding-left: 5px;
			li {
				margin-bottom: 5px;
				span {
					background: black;
					color: white;
					padding: 2px 5px;
				}
			}
		}
	}

	.enterprise_modal {
		margin-top: 40px;
	}

	#enterprise_contact {
		padding-left: 0px !important;
		padding-right: 0px !important;
		h2 {
			padding: 0 10px;
		}
		.btn {
			font-weight: bold;
			color: white;
		}
	}

	@media only screen and (max-width: $breakpoint-sm) {
		#enterprise_header {
			ul {
				margin-bottom: 20px;
			}
		}
	}

	.top_ul {
		span {
			font-size: 1.1em !important;
		}
	}

	#enterprise_contact {
		form {
			width: 75%;
			.custom_input {
				width: 100%;
				padding: 8px;
			}
			.btn {
				font-weight: bold;
				color: white;
			}
		}
	}

	.mp_logo {
		width: 100px;
		margin-left: 10px;
	}

	.response_section {
		.img_col {
			img {
				border-right: 10px solid $green;
			}
		}
		ul {
			border-left: 0;
		}
		li {
			margin-bottom: 10px !important;
			span {
				font-size: 1.4em;
				border-left: 0 !important;
			}
		}
		.img_right {
			img {
				border-left: 10px solid $green;
				border-right: 0;
			}
			ul {
				li {
					text-align: right;
				}
			}
		}
	}

	.api_section {
		.display-4 {
			line-height: 30px;
			text-align: center;
		}
		.font_logo {
			text-transform: uppercase;
			font-size: 1.2em;
		}
		ul {
			list-style: none;
			margin: 0;
			padding: 0;
			li {
				margin-bottom: 30px;
				span {
					background: white;
					text-transform: uppercase;
					font-size: 1.3em;
					padding: 5px 10px;
					border-left: 5px solid $green;
				}
			}
		}
	}

	.new_tag {
		font-size: 0.9em;
	}

	.price_table_card {
		box-sizing: border-box;
		border-left: 10px solid $green;
		p {
			font-size: 1.2em;
		}
	}
	.plan_message {
		strong {
			border-bottom: 4px solid $green;
		}
		.icon_box {
			color: white;
			background-color: black;
		}
	}

	#nosotros_page {
		.plan_message {
			strong {
				border-bottom: 4px solid $green;
			}
			.icon_box {
				color: white;
				background-color: black;
			}
		}
		#marketplace_section {
			h2 {
				span {
					background-color: black;
					color: white;
					padding: 0 10px;
				}
			}
		}

		@media only screen and (max-width: $breakpoint-sm) {
			#marketplace_section {
				h2 {
					div {
						margin: auto;
						width: 70%;
						padding: 5px 0px;
						background-color: black;
					}
				}
			}
		}

		#invitation_section {
			h5 {
				background-color: black;
				color: white;
			}
		}
	}

	#release_new {
		.release_paragraph {
			border-bottom: 1px solid #ccc;
		}
		.border_right {
			border-right: 1px solid #ccc;
		}
		.release_new_head {
			height: 100vh;
			width: 100%;
			position: relative;
			background-size: cover !important;
			h1 {
				font-size: 2.5rem;
				z-index: 1000;
			}
		}
		.release_new_head:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			background: rgba(0, 0, 0, 0.3);
			z-index: 999;
		}
		tr {
			th {
				padding: 5px 0;
				font-size: 1.3em;
			}
			td {
				padding: 10px 0;
			}
			& {
				border-bottom: 1px solid #ccc;
			}
		}
		.paragraph_title {
			font-size: 1.5em;
			letter-spacing: 3px;
		}
	}

	.parallax_bar {
		border: 5px solid $green;
	}

	.carousel.carousel-slider .control-arrow:hover {
		background: none;
	}

	.slide {
		background-color: rgba(255, 255, 255, 0) !important;
	}

	.adM {
		height: 500px;
		width: 300px;
		object-fit: cover;
	}

	.adSQ {
		height: 300px;
		width: 300px;
		object-fit: cover;
	}

	.imagenes {
		object-fit: cover;
	}

	.carousel .carousel-status {
		display: none;
	}

	.individuals_title {
		ul {
			margin: 0;
			padding: 0;
			list-style: none;
			display: flex;
			align-items: center;
			justify-content: space-around;
			li {
				background: white;
				font-size: 1.8em;
				text-transform: uppercase;
				padding: 5px 10px;
				letter-spacing: 4px;
				border-left: 5px solid $green;
			}
		}
	}

	#payment_loader {
		.btn-white {
			background-color: white !important;
		}
		div {
			color: black !important;
		}
	}

	.billing_modal {
		.modal-content {
			overflow: visible;
		}
	}

	@media only screen and (max-width: $breakpoint-sm) {
		.individuals_title {
			li {
				width: 100%;
			}
		}
	}

	#timeline {
		.subtitle {
			letter-spacing: 8px;
		}
		.keydriver {
			font-size: 30px;
			background: white;
			height: 30px;
			width: 30px;
			line-height: 0.7em;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 50px;
			border: 2px solid black;
		}
		.driver_divider {
			border-right: 0.5px dashed black;
			flex-grow: 1;
		}
		.year {
			position: absolute;
			top: -45px;
		}
		.timeline_text {
			flex-grow: 1;
			justify-content: flex-end;
		}
		.current_year {
			margin-top: 18px;
			margin-right: -15px;
			padding-top: 20px !important;
			background-color: black;
			color: white;
			padding-left: 2px;
			padding-right: 2px;
		}
	}

	@media only screen and (max-width: $breakpoint-sm) {
		.tlmobile {
			min-width: 100% !important;
		}
		.timeline_text {
			margin-top: 10px !important;
		}
	}

	#method_section {
		.method_link {
			border-left: 1px dashed black;
			border-top: 1px dashed black;
			width: 20px;
			position: absolute;
			right: 0;
			top: 23px;
			-webkit-transition: 0.5s ease-in-out;
			transition: 0.5s ease-in-out;
		}
		.title {
			font-size: 1.8em;
			letter-spacing: 4px;
			padding: 5px 10px;
			border-left: 5px solid $green;
			margin: 0 30px 0 0;
		}
		.plus {
			font-size: 30px;
			background: white;
			height: 35px;
			min-width: 35px;
			line-height: 0.7em;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 50px;
			border: 2px solid black;
			margin-right: 5px;
		}
		.selected_method_line {
			width: 0;
			border-bottom: 1px dashed black;
			-webkit-transition: 0.5s ease-in-out;
			transition: 0.5s ease-in-out;
		}
		.selected {
			width: calc(85px) !important;
		}
		.bullets {
			-webkit-transition: 0.3s ease-in-out;
			transition: 0.3s ease-in-out;
		}

		.text {
			flex-grow: 1;
		}
	}

	.number_plus {
		font-size: 0.6em;
	}

	.numbers_section {
		.col {
			border-right: 8px solid $green;
			padding: 0 10px;
		}
		.col:nth-child(5) {
			border-right: 0;
		}
		.divider {
			background-color: white;
		}
	}

	@media only screen and (max-width: $breakpoint-sm) {
		.numbers_section {
			.col {
				border-right: 0px;
				padding: 10px;
				min-width: 90%;
			}
			.divider {
				background-color: white;
				width: 30%;
				margin: auto;
				border-bottom: 8px solid $green;
			}
			p {
				margin-bottom: 20px;
			}
			.col:nth-child(5) {
				border-right: 0;
				border-bottom: 0;
				padding-top: 0px;
			}
		}
	}

	#news_social_bar {
		border-bottom: 2px solid #ccc;
	}
	#news_social_bar.border_top {
		border-bottom: 0 !important;
		border-top: 2px solid #ccc;
	}

	.news-carousel {
		padding-left: 100px;
		padding-right: 100px;
	}

	.news-carousel-slider {
		height: 400px;
	}

	@media only screen and (max-width: $breakpoint-sm) {
		.news-carousel-slider {
			height: 200px;
		}
		.news-carousel {
			padding-left: 3px;
			padding-right: 3px;
		}
	}

	.circle_graph {
		h1 {
			font-size: 3em;
		}
	}

	.plan-arrow {
		margin-top: 200px;
		margin-left: 20px;
		margin-right: 20px;
	}

	@media only screen and (max-width: $breakpoint-sm) {
		.plan-arrow {
			margin-top: 0px;
		}
	}

	@media only screen and (max-width: $breakpoint-sm) {
		.circle_graph {
			width: 100%;
			margin: auto;
			padding: 2px;
		}

		.circle_graph h5 {
			margin-bottom: 30px;
		}

		.circle-graph {
			width: 90%;
			margin-bottom: 20px;
			padding: 10px !important;
		}

		.circle-graph h5 {
			padding-left: 5px;
			padding-right: 5px;
		}

		.socialbar-tags-sm {
			margin-left: 0px !important;
		}
		.compartir {
			margin-right: 1.5rem !important;
		}
		.likes {
			float: left !important;
		}
		.share {
			float: right !important;
			display: inline;
		}
		.paragraph {
			border-right: none !important;
		}
	}

	.video_wrapper {
		iframe {
			max-width: 100%;
		}
	}

	.grecaptcha-badge {
		visibility: hidden;
	}

	.coming_soon_text {
		background: $green;
		color: black;
		padding: 0 5px;
	}

	@media only screen and (max-width: $breakpoint-md) {
		.paragraph {
			border-right: none !important;
		}
		.share {
			float: right !important;
			display: inline;
		}
		.circle-graph {
			width: 90%;
			margin-bottom: 20px;
			padding: 10px !important;
		}
		.comparator_table {
			min-width: 1140px;
		}

		.circle-graph h5 {
			padding-left: 5px;
			padding-right: 5px;
		}
	}

	@media only screen and (max-width: $breakpoint-md) and (max-width: $breakpoint-lg) {
		.likes {
			float: left !important;
		}
		.share {
			float: right !important;
			display: inline;
		}
		.socialbar-tags-sm {
			margin-left: 0px !important;
		}
	}

	.bg_gray {
		background: $darkGray;
	}

	.spacing_1 {
		letter-spacing: 3px !important;
	}

	.password_input {
		position: relative;
		button {
			background: none;
			color: inherit;
			border: none;
			padding: 0;
			font: inherit;
			cursor: pointer;
			outline: inherit;
		}
		.password_show {
			position: absolute;
			right: 0;
			top: 0;
		}
	}

	@media only screen and (min-width: 1182px) and (max-width: 1300px) {
		.spacing_1 {
			letter-spacing: 1px !important;
		}
	}

	.spacing_2 {
		letter-spacing: 8px !important;
	}

	.spacing_3 {
		letter-spacing: 10px !important;
	}

	.border_top {
		border-top: 1px solid #ccc;
	}

	.border_top_black {
		border-top: 1px solid black;
	}

	.search_bar {
		border: 0;
		border-radius: 0;
		font-size: 0.9em;
	}

	.search_icon_main {
		position: absolute;
		right: 15px;
		top: 15px;
		color: #ccc;
		z-index: 1000;
		font-size: 1.3em;
	}
	.mini_searchbar_results {
		position: absolute !important;
		background: white;
		width: 100%;
	}

	.mini_searchbar {
		transition: height 0.17s ease-in;

		.search_icon {
			transition: height 0.17s ease-in;
			position: absolute;
			right: 7px;
			top: 8px;
			color: #ccc;
			z-index: 9999999999;
		}

		.type_car_icon {
			transition: height 0.17s ease-in;
			position: absolute;
			left: 7px;
			top: 8px;
			z-index: 9999999999;
			cursor: pointer;
		}

		.type_moto_icon {
			transition: height 0.17s ease-in;
			position: absolute;
			left: 24px;
			top: 8px;
			z-index: 9999999999;
			cursor: pointer;
		}

		.form-control {
			font-size: 0.8em;
			border-radius: 0;
			position: relative;
			border: 0 !important;
		}
		.form-control:focus {
			border-color: white !important;
		}
	}
	a:hover {
		text-decoration: none !important;
	}
	a.nav-link {
		font-size: 0.9em;
	}

	@media only screen and (max-width: $breakpoint-sm) {
		a.nav-link {
			font-size: 1em;
		}
	}

	.notSelected {
		color: grey;
	}
	.custom_select__placeholder {
		font-weight: 400 !important;
	}

	.background_white {
		background-color: white;
	}

	.background_overlay {
		background-color: rgba(0, 0, 0, 0.3);
	}
	textarea:hover,
	input:hover,
	textarea:active,
	input:active,
	textarea:focus,
	input:focus,
	button:focus,
	button:active,
	button:hover,
	label:focus,
	.btn:active,
	.btn.active {
		outline: 0px !important;
		box-shadow: none !important;
	}
	.pointer {
		cursor: pointer;
	}
	.shadow-lg,
	.default_shadow,
	.default_shadow:focus,
	.default_shadow:hover {
		-webkit-box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.11) !important;
		-moz-box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.11) !important;
		box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.11) !important;
	}

	.test-profile {
		top: 78px;
	}

	.opacity_transition {
		-webkit-transition: opacity 0.15s ease-in-out;
		-moz-transition: opacity 0.15s ease-in-out;
		-ms-transition: opacity 0.15s ease-in-out;
		-o-transition: opacity 0.15s ease-in-out;
		transition: opacity 0.5s ease-in-out;
	}
	.background_gray {
		background-color: $grayBackground;
	}

	.gray_divider {
		border-top: 2px solid #ccc;
		border-bottom: 2px solid #ccc;
	}

	.background_cyan {
		background-color: $cyan;
	}

	.custom_select__control.css-yk16xz-control {
		border: 0;
		.custom_select__placeholder {
			color: black;
			font-weight: bold;
		}
	}
	.plan_card_container {
		margin-right: 10px;
		margin-left: 10px;
		margin-bottom: 20px;
		position: relative;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		h3 {
			font-size: 1.6em;
		}
		.plan_card {
			max-width: 340px;
			height: 100%;
			border-radius: 10px;
			overflow: hidden;
			display: flex;
			flex-direction: column;
			.bg_blue {
				flex: 0;
			}
			.body {
				font-size: 0.8rem;
				flex-grow: 1;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
			}
			.border-bottom {
				border-bottom: 1px solid $gray;
			}
			.price_selector {
				font-size: 0.65rem;
				border-radius: 10px;
				overflow: hidden;
				.button {
					flex: 1;
				}
			}
		}
		.premium_text {
			position: absolute;

			@media (min-width: 300px) {
				top: -28px;
				font-size: 0.9em;
			}
			@media (min-width: 992px) {
				top: -28px;
			}
			& {
				text-align: center;
			}
			p {
				background: $yellow;
			}
		}
	}

	.font_logo {
		text-transform: uppercase;
		font-style: italic;
		font-weight: 200;
		text-transform: uppercase;
		& {
			strong {
				font-weight: 700;
			}
		}
		& {
			letter-spacing: normal;
		}
	}

	.custom_select__control--is-focused {
		border-color: black;
		box-shadow: none;
	}
	.navbar {
		padding: 0px 10px 0px 30px;
		#profile_thumbnail {
			p {
				margin: 0;
			}
			& {
				position: relative;
			}
			.profile_thumbnail_picture {
				width: 40px;
				height: 40px;
				object-fit: cover;
			}
			.profile_thumbnail {
				width: 40px;
				height: 40px;
				background-color: white;
			}
			.nav-item {
				padding: 15px 0;
			}
			#user_menu {
				position: absolute;
				top: 78px;
				background-color: white;
				width: 100%;
				border: 1px solid black;
			}
			#user_menu.individual {
				bottom: -50px;
			}
		}
		.navbar-nav {
			margin-top: 8px;
			.nav-link,
			.nav-link:hover {
				color: white;
			}
			.nav-item {
				margin: 0 15px;
			}
		}

		#header_img_link {
			height: 54px;
			box-sizing: border-box;
		}

		#user_menu ul {
			margin-bottom: 0px !important;
		}

		@media only screen and (min-width: $breakpoint-lg) {
			#header_img_link {
				height: 98px;
			}
		}
		.navbar-brand {
			margin-right: 30px;
			font-size: 1.7rem;
			height: auto;
			.text-dark {
				color: $dark;
			}
		}
	}

	.scroll-hint-icon {
		width: 11rem;
		z-index: 50;
		padding: 10px;

		.scroll-hint-text {
			word-wrap: break-word !important;
		}
	}

	@media only screen and (max-width: $breakpoint-sm) {
		.profile-mobile {
			display: none !important;
		}
		.credits {
			text-align: left !important;
		}
	}

	.bw_image {
		-webkit-filter: grayscale(100%);
		-moz-filter: grayscale(100%);
		filter: grayscale(100%);
		-webkit-transition: filter 0.5s ease-in-out;
		transition: filter 0.5s ease-in-out;
	}
	.magazine_container {
		position: relative;
		.magazine_loader {
			width: 450px;
			height: 450px;
		}
		.magazine_reader {
			display: flex;
			justify-content: center;
			.magazine_page {
				border-radius: 5px;
				overflow: hidden;
				max-height: auto;
				height: auto;
			}
		}
		.page_control_container {
			position: absolute;
			right: 0;
			left: 0;
			bottom: 25px;
			-webkit-transition: 0.5s ease-in-out;
			transition: 0.5s ease-in-out;
			.page_control {
				background: white;
				border-radius: 50px;
				padding: 10px;
			}
		}
	}

	@media only screen and (max-width: $breakpoint-sm) {
		.revista-mobile {
			padding-left: 0px !important;
			padding-right: 0px !important;
		}
	}

	@media only screen and (min-width: $breakpoint-md) {
		.infogrid_container {
			// overflow: scroll;
			max-height: 400px;
			max-width: 100% table {
				border-spacing: 0;
			}
		}

		.testCol {
			overflow: scroll;
			max-height: 400px;
			max-width: 100%;
			box-sizing: border-box;
			border-collapse: separate;
		}

		.dropdown {
			z-index: 20;
		}

		.testCol {
			thead {
				th {
					position: sticky;
					top: 0;
				}
			}
			td:first-child,
			th:first-child {
				position: sticky;
				left: 0;
				z-index: 1;
				background-color: white;
			}
			td:nth-child(2),
			th:nth-child(2) {
				position: sticky;
				left: 60px;
				z-index: 2;
				max-width: 220px !important;
				min-width: 220px !important;
				background-color: white;
			}
			td:nth-child(3),
			th:nth-child(3) {
				position: sticky;
				left: calc(220px + 60px);
				z-index: 3;
				max-width: 70px !important;
				min-width: 70px !important;
				background-color: white;
			}
			td:nth-child(4),
			th:nth-child(4) {
				position: sticky;
				left: calc(220px + 60px + 70px);
				z-index: 3;
				background-color: white;
			}
			th:first-child,
			th:nth-child(2),
			th:nth-child(3),
			th:nth-child(4) {
				z-index: 7;
			}

			th {
				position: sticky;
				top: 0;
				background: #eee;
			}
		}
	}

	.bw_image:hover {
		filter: grayscale(0);
	}

	.bg1 {
		background-image: url('../static/img/tesla_background.jpg');
		background-position: top;
	}
	.bg2 {
		background-image: url('../static/img/banner_motos.jpg');
	}

	@media only screen and (max-width: $breakpoint-sm) {
		.bg1 {
			height: 95vh !important;
		}
		.bg2 {
			height: 95vh !important;
		}
	}

	.font_smaller {
		font-size: 0.8em;
	}
	.masthead {
		padding-top: 30px;
		margin-top: 30px;
		min-height: 530px;
		height: 85vh;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		-webkit-transition: background-image 1s ease-in-out;
		transition: background-image 1s ease-in-out;
		.display-4 {
			letter-spacing: 8px;
			@media (max-width: $breakpoint-md) {
				font-size: 3.5rem;
			}
			@media (max-width: $breakpoint-sm) {
				font-size: 1.5rem;
			}
		}

		@media only screen and (max-width: $breakpoint-sm) {
			.masthead {
				padding-top: 0px;
				margin-top: 0px;
			}
		}

		@media only screen and (max-width: $breakpoint-sm) {
			.masthead {
				padding-top: 0px;
				margin-top: 0px;
			}
		}

		#home_searchbar {
			height: 10px;
			input {
				padding: 25px;
			}
			a {
				div {
					height: 100%;
					.btn {
						border-bottom-left-radius: 0;
						border-top-left-radius: 0;
					}
					button:hover {
						color: black;
					}
				}
			}
		}

		h5,
		p,
		h6 {
			letter-spacing: 12px;
		}
		.form-control:focus {
			border: none;
		}
		#suscribe_button {
			color: black;

			border-radius: 50px;
			padding: 0px 30px;
		}
		#more_info_button {
			color: white;
			letter-spacing: 1px;
			cursor: pointer;
		}
		#more_info_button_text:hover {
			border-bottom: 2px solid white;
			color: white;
		}
	}
	#subscriptions_section {
		h1 {
			letter-spacing: 5px;
		}
		p.text-secondary {
			font-size: 0.85rem;
		}
		.card {
			width: 100%;
			:hover {
				.subscription_card_bullets {
					opacity: 1;
				}
			}
			.subscription_card_bullets {
				opacity: 0;
				background-color: rgba(0, 0, 0, 0.75);
				-webkit-transition: opacity 0.15s ease-in-out;
				-moz-transition: opacity 0.15s ease-in-out;
				-ms-transition: opacity 0.15s ease-in-out;
				-o-transition: opacity 0.15s ease-in-out;
				transition: opacity 0.25s ease-in-out;
				ul {
					li {
						color: white;
						font-size: 0.9em;
					}
				}
				button {
					letter-spacing: 7px;
					font-size: 1em;
					width: 221px;
				}
				.btn-dark {
					background: $dark;
					border: $dark;
				}
			}
			.bg-dark {
				background-color: $dark !important;
				h5 {
					color: white;
				}
			}
		}
	}

	.user_type_card:hover {
		background-color: rgba(0, 0, 0, 0.01);
		opacity: 1;
	}

	@media only screen and (max-width: $breakpoint-sm) {
		#subscriptions_section {
			padding-left: 5px;
			padding-right: 5px;
		}
		#subscription_section .container {
			margin-bottom: 0px !important;
		}
		.find-mobile {
			padding-top: 0px !important;
		}
		.mb-mobile {
			margin-bottom: 0px !important;
		}
	}

	#progress_bar {
		background-color: #e5e5e5;
		height: 10px;
		#progress_bar_fill {
			background: black;
			-webkit-transition: width 0.15s ease-in-out;
			-moz-transition: width 0.15s ease-in-out;
		}
	}

	.text-professional-options {
		font-size: 0.9em;
	}

	.text-login {
		font-size: 1rem;
	}

	.bg_black {
		background: black;
		color: white;
	}
	.register_subscription {
		background: rgba(0, 0, 0, 0.7) !important;
		color: white;
	}
	#infograph_section {
		.display-3 {
			@media (max-width: $breakpoint-md) {
				font-size: 3.5rem;
			}
		}
		h4 {
			letter-spacing: 5px;
		}
		.bullets_container {
			height: 30px;
		}
		.infograph_bullet {
			width: 15px;
			height: 15px;
			background: $dark;
			border-radius: 100px;
			cursor: pointer;
			-webkit-transition: padding 0.15s ease-in-out;
			-moz-transition: padding 0.15s ease-in-out;
			-ms-transition: padding 0.15s ease-in-out;
			-o-transition: padding 0.15s ease-in-out;
			transition: padding 0.3s ease-in-out;
		}
		.rounded-circle {
			width: 40px;
			height: 40px;
			border: 1.5px solid black;
			z-index: 10;
			@media (max-width: $breakpoint-md) {
				width: 30px;
				height: 30px;
			}
			strong {
				margin-bottom: 0;
			}
		}

		.key_driver_info_box {
			width: 200px;
			z-index: 800;
		}
		.key_driver_right {
			top: 50%;
			left: 0;
			.key_driver_divider {
				border-right: 1px dashed black;
				border-top: 1px dashed black;
				transition: width 1s;
			}
		}
		.key_driver_left {
			top: 50%;
			right: 0;
			.key_driver_divider {
				border-left: 1px dashed black;
				border-top: 1px dashed black;
				transition: width 1s;
			}
		}
	}
	#magazine_section {
		.text-muted {
			font-size: 0.9em;
		}
		.countdown_time {
			h1 {
				font-size: 3.25rem;
			}
			h5 {
				font-size: 0.8rem;
			}
		}
	}
	#news_container {
		margin: 0px;
	}
	#news_section {
		@media (min-width: $breakpoint-lg) {
			padding-right: 7rem !important;
			padding-left: 7rem !important;
		}

		.news__typeContainer {
			display: flex;
			overflow-y: hidden;
			overflow-x: auto;
		}
		.news__typeContainer::-webkit-scrollbar {
			height: 0 !important;
			width: 0 !important ;
			background: transparent;
		}

		ul {
			list-style: none;
			padding: 0;
			margin: 0;

			.bg-dark {
				background-color: $dark !important;
			}

			li.border-left {
				border-left: 1px solid #ccc !important;
			}
		}

		@media only screen and (max-width: $breakpoint-sm) {
			.newsmobile {
				font-size: 120%;
			}
			#news_search_input {
				margin-top: 15px !important;
				width: 90% !important;
			}
		}

		.news__typeItem {
			white-space: nowrap;
			letter-spacing: 1px;
		}

		.new_background {
			-webkit-filter: grayscale(100%);
			-moz-filter: grayscale(100%);
			filter: grayscale(100%);
			transition: all 0.5s ease;
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
		}

		.new_background:hover {
			-webkit-filter: grayscale(0%);
			-moz-filter: grayscale(0%);
			filter: grayscale(0%);
		}

		.primary_new {
			width: 100%;
			height: 100%;
			position: relative;
			min-height: 200px;
			@media (max-width: $breakpoint-md) {
				padding-top: 100% !important;
			}
		}

		.secondary_new {
			width: 100%;
			padding-top: 100% !important;
			position: relative;
		}
	}
	.page_default_margin {
		margin: 125px 0 15px;
	}

	@media only screen and (max-width: $breakpoint-sm) {
		.page_default_margin {
			margin: 90px 0 15px;
		}
	}

	.square_button {
		border: 0;
		min-height: 36px;
		border-radius: 6px;
	}
	.square_button.disabled {
		background: #c7c7c7;
	}

	.gray_divider {
		border-top: 1px solid #ccc;
	}

	.custom-file-label::after {
		content: 'Buscar' !important;
	}

	.btn_sm {
		font-size: 0.8em;
		display: flex;
		align-items: center;
	}

	.info_pro_details {
		border-radius: 5px;
		.details_pills {
			border-left: 1px solid black;
			.info_pro_pill {
				min-height: 30px;
				border-radius: 50px;
				overflow: hidden;
			}
		}
	}

	.info_pro_slider {
		height: 290px;
		background: white;
		border-radius: 10px;
		.bar {
			height: 100%;
			width: 10px;
			background: #ccc;
			border-radius: 10px;
			position: relative;
		}
		.bar_fill {
			width: 100%;
			background: $green;
			border-radius: 10px;
		}
		.bar_anchor {
			width: 25px;
			height: 25px;
			position: absolute;
			border-radius: 50px;
			background: black;
			border: 3px solid white;
			left: -7px;
		}
	}

	.info_pro_slider_values {
		position: relative;
		.value_container {
			position: relative;
			flex-grow: 1;
		}
		.value {
			position: absolute;
		}
	}

	.gray_row {
		background-color: #ccc;
	}

	.blurred_graph {
		position: relative;
		backdrop-filter: saturate(180%) blur(5px);
		border-radius: 10px;

		h5 {
			position: absolute;
			bottom: -5px;
		}
		.overlay {
			position: absolute;
			background: rgba(0, 0, 0, 0.8);
			top: 0;
		}
	}

	@media only screen and (max-width: $breakpoint-sm) {
		.blurred_graph img {
			width: 100% !important;
			backdrop-filter: saturate(180%) blur(5px);
			border-radius: 10px;
			padding-left: 10px;
		}
		.details_pills {
			border-left: 0px !important;
			margin-top: 40px;
			margin-bottom: 0px !important;
		}
	}

	.crossed {
		width: 100%;
		text-align: center;
		border-bottom: 1px solid #000;
		line-height: 0.1em;
		margin: 10px 0 20px;
		span {
			background: #fff;
			padding: 0 10px;
		}
	}

	.car_profile_image {
		position: relative;
		h5 {
			position: absolute;
			bottom: 8px;
			left: 10px;
			color: white;
		}
	}

	.modal-content {
		overflow: hidden;
	}

	.gray_background {
		background: $grayBackground;
	}

	.profile_header {
		border-bottom: 1px solid #ccc;
	}

	@media only screen and (max-width: $breakpoint-sm) {
		.profile-plan h2 {
			font-size: 120%;
		}
	}

	@media only screen and (max-width: $breakpoint-md) {
		.profile-plan h2 {
			font-size: 180%;
		}
	}

	@media only screen and (max-width: $breakpoint-lg) {
		.profile-plan h2 {
			font-size: 150%;
		}
	}

	#profile_image {
		background: $black;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 70px;
		height: 4.2rem;
		width: 4.2rem;

		overflow: hidden;
		.profile_image_preview {
			height: 100%;
			width: 100%;

			object-fit: cover;
		}
	}

	#news_search_input {
		padding: 5px;
		border: 0;
		border-bottom: 1px solid #ccc;
	}
	#news_search_input:focus {
		border: 0;
		border-bottom: 1px solid black;
	}

	.bg_black {
		background: black !important;
	}

	#advanced_search_page {
		#search_table {
			height: 70vh;
			border-top: 1px solid #ccc;
			.bg-dark {
				background: $dark;
			}
			.position-absolute {
				z-index: 900;
			}
			.advanced_search_column {
				max-height: 100%;
				display: flex;
				flex-direction: column;
				background: white;
				.options_container {
					flex-grow: 1;
					overflow-x: hidden;
					overflow-y: scroll;
					li {
						.dollar_value {
							font-size: 0.8em;
						}
						.chart_price_dollar {
							position: absolute;
							font-size: 0.8em;
							top: 0;
							width: 100%;
							left: 0;
							p {
								text-align: center;
							}
						}
						& {
							font-size: 0.9em;
							position: relative;
						}
					}
					li:last-child {
						margin-bottom: 40px;
					}
				}

				.options_container::-webkit-scrollbar-track {
					background-color: #f5f5f5;
					width: 2px;
				}

				.options_container::-webkit-scrollbar {
					width: 10px;
					background-color: #f5f5f5;
				}

				.options_container::-webkit-scrollbar-thumb {
					background-color: #191919;
				}

				.column_title {
					top: 0;
					background: white;
				}
			}
			.background_column {
				background: $columnBackground !important;
				input:hover {
					box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
				}
			}
			ul {
				list-style: none;
				padding: 0;
				margin: 0;
				color: $lightGray;
				li {
					border-radius: 0 !important;
				}
			}
			button {
				width: 80%;
			}
			a {
				width: 100%;
			}
			.btn-dark {
				background: $dark;
				color: white;
				background-color: black;
				border-radius: 50px;
			}
			.btn-light {
				background: white;
				border: 1px solid $dark;
			}
		}
	}
	#product_sheet {
		margin: 105px 0 15px;

		.measurement-pill {
			background: white;
			border-radius: 50px;
		}
		.btn {
			letter-spacing: 5px;
			border-radius: 50px;
		}

		.btn-dark {
			background: $dark;
			color: white;
			background-color: black;
		}
		.btn-light {
			background: white;
			color: black;
			border: 2px solid $dark;
		}

		#like_icon {
			color: black;
			font-size: 2rem;
			border-radius: 25px;
			background: #dddddd;
			padding: 10px;
		}

		.new_background {
			-webkit-filter: grayscale(100%);
			-moz-filter: grayscale(100%);
			filter: grayscale(100%);
			transition: all 0.5s ease;
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
		}

		.new_background:hover {
			-webkit-filter: grayscale(0%);
			-moz-filter: grayscale(0%);
			filter: grayscale(0%);
		}
		.secondary_new {
			background-color: red;
			width: 100%;
			padding-top: 100% !important; /* 1:1 Aspect Ratio */
			position: relative;
		}
		.background_overlay {
			background-color: rgba(0, 0, 0, 0.3);
		}
	}

	#visual_search_page {
		.page_default_margin {
			margin: 105px 0 15px;
		}
		.btn-dark {
			background: $dark;
			color: white;
			background-color: black;
			letter-spacing: 5px;
			border-radius: 50px;
		}

		ul {
			list-style: none;
			padding: 0;
			margin: 0;

			.bg-dark {
				background-color: $dark !important;
			}

			li.border-left {
				border-left: 2px solid $dark !important;
			}
		}

		.new_background {
			-webkit-filter: grayscale(100%);
			-moz-filter: grayscale(100%);
			filter: grayscale(100%);
			transition: all 0.5s ease;
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
		}

		.new_background:hover {
			-webkit-filter: grayscale(0%);
			-moz-filter: grayscale(0%);
			filter: grayscale(0%);
		}

		.primary_new {
			background-color: red;
			width: 100%;
			height: 100%;
			position: relative;
			@media (max-width: $breakpoint-md) {
				padding-top: 100% !important;
			}
		}

		.secondary_new {
			background-color: red;
			width: 100%;
			padding-top: 100% !important; /* 1:1 Aspect Ratio */
			position: relative;
		}

		.grey-container {
			background-color: rgb(234, 234, 234);
			border-top: 2px solid rgb(134, 148, 166);
		}

		.light-grey-container {
			background-color: rgb(247, 247, 247);
		}

		#car_info_container {
			border-left: 2px solid rgb(134, 148, 166);
		}
	}

	@media only screen and (max-width: $breakpoint-sm) {
		#car_info_container {
			border-left: none !important;
		}
	}

	.price_chart_table {
		max-height: 300px;
		overflow-y: auto;
		overflow-x: auto;
		li:last-child {
			margin-bottom: 10%;
		}
	}

	.price_chart_table::-webkit-scrollbar {
		display: none !important;
	}

	.chart_contact {
		flex-grow: 1;
	}

	.preview p {
		margin-bottom: 0;
	}

	.preview {
		display: none;
		width: '100%';
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.secondary_new:hover,
	.primary_new:hover {
		.preview {
			display: block;
		}
	}

	#historic_price_search_page {
		.page_default_margin {
			margin: 305px 0 15px;
		}
		.btn-dark {
			background: $dark;
			color: white;
			background-color: black;
			letter-spacing: 5px;
			border-radius: 50px;
		}

		#car_filters_container {
			border-top: 2px solid rgb(134, 148, 166);
			border-bottom: 2px solid rgb(134, 148, 166);
		}
	}

	#footer {
		background: $dark;
		#top_footer {
			background: $darkerGray;
			#footer_social_icons {
				color: $grey;
				font-size: 1.6rem !important;
				margin-bottom: 0;
			}
			#footer_suscribe {
				display: flex;
				flex-direction: row;
				svg {
					color: $grey;
					font-size: 1.6rem;
				}
			}
		}
		.bottom_brand {
		}
		#bottom_footer {
			background: $black;
			ul {
				list-style: none;
				padding: 0;
				margin: 0;
				color: $lightGray;
			}
			a {
				color: white;
			}
		}
	}

	.filter_options_container {
		border-top: 1px solid #ccc;
	}

	.main_btn {
		border: 1px solid #ccc;
		max-width: 180px;
		font-size: 0.85rem;
	}

	.btn-black {
		background: $black;
	}

	.result_card {
		border-bottom: 1px solid black;
		.car_years {
			overflow: hidden;
			white-space: nowrap; /* Don't forget this one */
			text-overflow: ellipsis;
			p {
				font-size: 1.1em;
			}
		}
	}
	.result_card:last-child {
		border-bottom: 0 !important;
	}
	.link {
		cursor: pointer;
		// padding-left: 10px;
	}

	.divider {
		background-color: $black;
		width: 100%;
		height: 10px;
	}

	.divider-sm {
		background-color: #ccc;
		width: 100%;
		height: 1px;
	}

	.split_button {
		cursor: default;
		width: 100%;
		max-width: 300px;
		background: $dark;
		color: white;
		letter-spacing: 5px;
		border-radius: 50px;
		font-size: 1em;
	}

	.color_blue {
		color: $blue;
	}

	.color_white {
		color: white;
	}

	.color_yellow {
		color: #fbc139;
	}

	.gray_icon {
		color: #ccc;
	}

	.color_green {
		color: $green;
	}

	.code_sent {
		background: #10ffdc;
		color: black;
		border-radius: 5px;
		padding: 4px;
	}

	.img-logo {
		width: 75%;
	}

	@media screen and (max-width: $breakpoint-sm) {
		.img-logo {
			width: 55%;
		}
	}

	@media screen and (max-width: $breakpoint-lg) {
		.img-logo {
			width: 35%;
		}
	}

	.btn-circle {
		width: 30px;
		height: 30px;
		padding: 6px 0px;
		border-radius: 15px;
		text-align: center;
		font-size: 12px;
		line-height: 1.42857;
	}

	.color_gray {
		color: $lightGray;
	}

	.color_light_blue {
		color: $lightBlue;
	}

	.bg_white {
		background-color: white;
	}

	.plan_section_card {
		height: 100%;
		min-height: 228px;
		.title {
			background: $black;
			color: white;
			padding: 5px 10px;
			text-transform: uppercase;
			font-weight: bold;
		}
		.body {
			flex: 1;
			border: 1px solid black;
		}
		.bg_white {
			background-color: white;
		}
		.bg_green {
			background-color: $green;
		}
	}

	.bg_green {
		background-color: $green !important;
	}

	.new_price_option {
		background: $green;
		color: white;
		border-bottom: 0 !important;
		.chart_price_dollar {
			color: black;
		}
	}

	.comparator_image {
		position: relative;
		min-width: 6rem;
		width: 100%;
		border: 1px solid black;
		.content {
			position: absolute;
			min-width: 6rem;
			min-height: 6rem;
			width: 100%;
			height: 100%;
		}
	}

	.comparator_image:after {
		content: '';
		display: block;
		padding-bottom: 100%;
	}

	.comparator_modal {
		.modal-content {
			overflow: visible;
			border-radius: 5px;
		}
	}

	#share_bar {
		z-index: 10;
		position: fixed;
		background: rgba(0, 0, 0, 0.6);
		top: 40%;
		padding: 13px;
		right: 0;
	}

	#home_parallax {
		ul {
			margin: 0;
			list-style: none;
			li {
				font-size: 1.4em;
				margin-bottom: 20px;
			}
		}
	}

	#home_search_results {
		width: 100%;
		background: white;
		margin-top: 10px;
		p {
			border-top: 1px solid #ccc;
			letter-spacing: normal;
			padding: 10px;
			margin-bottom: 0;
		}
		ul {
			overflow: hidden;
			position: absolute;
			list-style: none;
			margin: 0;
			padding: 0;
			width: 100%;
			max-height: 30vh;
			overflow-y: auto;
			li {
				width: 100%;
				background: white;
				padding: 10px;
				border-bottom: 1px solid #ccc;
				a {
					color: black;
				}
			}
			li:last-child {
				border-bottom: 0;
			}
		}
	}

	#result_section {
		border-top: 1px solid black;
	}

	.chart_price_option {
		font-size: 1.2em;
		border-bottom: 1px solid #ccc;
		position: relative;
		.chart_price_dollar:nth-child(1) {
			top: 0;
		}
		.chart_price_dollar {
			position: absolute;
			right: -20%;
			top: 8%;
			border-radius: 10px;
			right: 30%;
			background: $columnBackground;
			z-index: 99999999999999999;
			p {
				font-size: 0.8em;
			}
		}
	}

	.bordered_dollar {
		border-top: 1px solid #ccc;
		border-bottom: 1px solid #ccc;
	}

	.recharts-cartesian-axis-tick-value {
		stroke: none !important;
		font-size: 0.9em;
	}

	.default_modal {
		.modal-body {
			border-radius: 0 !important;
		}
	}

	.chart_price_option:hover {
		background: $black;
		border-bottom: 1px solid $black;
		span,
		small {
			color: white;
			font-weight: bold;
		}
	}

	.border_bottom_dark {
		border-bottom: 1px solid #191919;
	}

	.dollar_pill_container {
		font-size: 0.7rem;
		small {
			font-size: 8px;
		}
	}

	.dollar_pill {
		overflow: hidden;
		border-radius: 10px !important;
		max-width: 160px !important;
		.bg_black {
			flex: 1;
			background-color: $black;
			color: white;
		}
		.bg_blue {
			color: white;
			flex: 1;
			background-color: $blue !important;
			text-align: center;
		}
		.dollar_title {
			flex: 2;
			font-size: 0.8em;
		}
	}

	.bg_blue {
		color: white;
		flex: 1;
		background-color: $blue;
		text-align: center;
	}

	.vehicle_type_selector {
		overflow: hidden;
		color: #ccc;
		border-radius: 10px;
		width: 180px;
		font-size: 0.7rem;
		.bg_dark {
			background-color: $black;
			color: white;
		}
		.option {
			padding: 6px;
		}
	}

	.report_type_selector {
		overflow: hidden;
		color: #ccc;
		border-radius: 10px;
		.bg_dark {
			background-color: $black;
			color: white;
		}
		.option {
			padding: 6px;
		}
		div {
			font-size: 0.8em;
		}
	}

	.modal_close {
		width: 30px;
		height: 30px;
		background: black;
		color: white;
		border-radius: 50px;
	}

	.text_area {
		min-height: 200px;
	}

	.text_blue {
		color: $blue !important;
	}

	.text_green {
		color: $green !important;
	}

	.bg_black {
		background-color: $black;
		color: white;
	}

	.recharts-legend-wrapper {
		display: none;
	}

	.facebook_button {
		background: $facebook;
	}

	.google_button {
		background: $google;
		p {
			border-left: 1px solid white;
		}
	}

	.apple_button {
		background: black;
		p {
			border-left: 1px solid white;
		}
	}

	.border-bottom-1 {
		border-bottom: 1px solid black;
	}

	.color_red {
		color: darkred;
	}

	.color_black {
		color: black;
	}
	.color_black:hover {
		color: black;
	}

	#login_modal {
		font-size: 0.8em;
		li {
			font-size: 1.2em;
		}
	}
	.custom_input {
		-webkit-appearance: none !important;
		border: 0;
		border-radius: 10px;
		-webkit-box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.11) !important;
		-moz-box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.11) !important;
		box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.11) !important;
	}
	.custom_input:focus,
	custom_input:hover {
		border: 1px solid #ccc !important;
		box-shadow: none;
	}

	.custom_input:focus-visible {
		box-shadow: none !important;
	}

	.custom_input:disabled {
		background: #ccc !important;
	}

	.overlay_container {
		position: relative;
	}

	.scroll_overlay {
		width: 100%;
		height: 50px;
		position: absolute;
		bottom: 0;
		background: rgb(255, 255, 255);
		background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
	}

	.pdf_overlay {
		width: 100%;
		height: 50px;
		position: absolute;
		bottom: 0;
		background: rgb(255, 255, 255);
		background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
	}

	.custom_input {
		-webkit-appearance: none !important;
		border-radius: 4px;
		box-sizing: border-box;
		border: 1px solid white !important;
		-webkit-box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.11) !important;
		-moz-box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.11) !important;
		box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.11) !important;
	}
	.custom_input:focus,
	.custom_input:hover {
		border: 1px solid #ccc !important;
		-webkit-box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.11) !important;
		-moz-box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.11) !important;
		box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.11) !important;
	}
	.custom_input:disabled {
		background: #ccc;
		border: 1px solid #ccc !important;
	}
	.result_quantity {
		font-size: 0.8em;
	}

	.top_margin {
		margin-top: 105px;
	}

	.logout_screen {
		position: absolute;
		z-index: 99999999;
		height: 100vh;
		width: 100vw;
		background: white;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.counter {
		border-left: 10px solid $green;

		h1 {
			font-size: 4em;
		}
	}

	.old_magazines {
		overflow-y: scroll;
	}

	.old_magazines::-webkit-scrollbar {
		display: none;
	}

	.magazine_carousel::-webkit-scrollbar {
		display: none;
	}

	.magazine_carousel {
		overflow-x: scroll;

		.magazine_container {
		}

		img {
			height: 230px;
			-webkit-filter: grayscale(100%);
			-moz-filter: grayscale(100%);
			filter: grayscale(100%);
			-webkit-transition: filter 0.5s ease-in-out;
		}
		img:hover {
			-webkit-filter: grayscale(0%);
			-moz-filter: grayscale(0%);
			filter: grayscale(0%);
		}
	}

	.session-modal {
		background-color: rgba($color: #fc4f4f, $alpha: 0.3);
		border-radius: 8px;
	}

	@media screen and (max-width: $breakpoint-md) {
		.visual-overlay {
			z-index: 9999999 !important;
			position: absolute;
			background-color: rgba($color: #000000, $alpha: 0.8);
			height: 100%;
			width: 100vw;
			top: 0;
			color: white;
			text-align: center;
			display: flex;
			justify-content: center;
			flex-direction: column;
			align-items: center;
		}
	}

	.pdf_container {
		height: 500px;
	}

	.separator_bar {
		position: relative;
		border-bottom: 1px solid #ccc;
		.separator {
			width: 70px;
			height: 6px;
			background: black;
			position: absolute;
			top: -3px;
		}
	}
	.infopro_search {
		.quantity_selector {
			padding: 0 10px;
			border-right: 2px solid black;
		}
		.quantity_selector:last-child {
			border-right: 0;
		}
	}

	.footer_image {
		position: relative;
		height: 100%;

		#footer_image_div {
			min-height: 350px;
		}
		.footer_image_text {
			position: absolute;
			top: 0;
			right: 0;
			height: 50%;
		}
	}

	@media only screen and (max-width: $breakpoint-sm) {
		.footer_image {
			position: relative;
			height: 100%;

			#footer_image_div {
				min-height: auto;
			}
			.footer_image_text {
				font-size: 80%;
				position: absolute;
				top: 0;
				right: 0;
				height: 50%;
				width: 80%;
				padding-right: 20px !important;
			}
		}
	}

	.infogrid_card_header {
		min-height: 42px;
		display: flex;
		justify-content: center;
		align-items: center;
		img {
			width: 145px;
		}
	}

	.free_price {
		padding-top: 28px;
	}

	.carousel_item {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.company_image {
		display: flex;
		justify-content: center;
		img {
			max-width: 90%;
			max-height: 100px;
			user-select: none;
		}
	}

	.react-multiple-carousel__arrow--right {
		right: 0 !important;
	}

	.react-multiple-carousel__arrow--left {
		left: 0 !important;
	}

	.react-multiple-carousel__arrow {
		background: rgba(0, 0, 0, 0);
	}
	.react-multiple-carousel__arrow::before {
		color: black;
	}

	.react-multiple-carousel__arrow:hover {
		background: #f7f7f7;
	}

	.bg-danger {
		background-color: #dc3545 !important;
	}

	@media only screen and (max-width: $breakpoint-lg) {
		.footer_image_text {
			position: absolute !important;
			top: 40% !important;
			right: 0 !important;
			height: auto !important;
		}
		.footer-text {
			font-size: 250% !important;
		}
		.footer-text-logo {
			font-size: 100% !important;
		}
	}

	@media only screen and (max-width: $breakpoint-md) {
		.footer_image_text {
			position: absolute !important;
			top: 40% !important;
			right: 0 !important;
			height: auto !important;
		}
		.footer-text {
			font-size: 180% !important;
		}
		.footer-text-logo {
			font-size: 100% !important;
		}
	}

	@media only screen and (max-width: $breakpoint-sm) {
		.w-75 {
			width: 100% !important;
		}
	}

	.input-range__slider {
		height: 24px !important;
		width: 24px !important;
		background-color: #fff !important;
		border: 2px solid black !important;
		margin-top: -16px !important;
		box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1), 0px 20px 20px rgba(0, 0, 0, 0.1) !important;
	}
	.input-range__slider:hover {
		box-shadow: inherit !important;
	}
	.input-range__slider:active {
		.input-range__label {
			display: none !important;
		}
		& {
			box-shadow: inherit !important;
		}
	}
	.input-range__slider:focus {
		box-shadow: inherit !important;
	}
	.input-range__track {
		background: #989898 !important;
		height: 8px !important;
		border-radius: 4px !important;
		box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1), 0px 20px 20px rgba(0, 0, 0, 0.1) !important;
	}
	.input-range__track--active {
		background-color: black !important;
		height: 8px !important;
		border-radius: 4px !important;
		box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1), 0px 20px 20px rgba(0, 0, 0, 0.1) !important;
	}

	.input-range__label--min,
	.input-range__label--max {
		display: none;
	}

	.input-range__label {
		font-family: 'Montserrat', sans-serif !important;
	}

	.input-range__label--value {
		margin-top: -10px !important;
	}

	.pagination {
		margin: 15px auto;
		display: flex;
		list-style: none;
		outline: none;
		justify-content: right;
	}
	.pagination > .active > a {
		background-color: rgb(119, 119, 119);
		border-color: black;
		color: #fff;
	}
	.pagination > li > a {
		border: 1px solid black;
		padding: 5px 10px;
		outline: none;
		cursor: pointer;
	}
	.pagination > .active > a,
	.pagination > .active > span,
	.pagination > .active > a:hover,
	.pagination > .active > span:hover,
	.pagination > .active > a:focus,
	.pagination > .active > span:focus {
		background-color: black;
		border-color: black;
		color: white;
		outline: none;
	}
	.pagination > li > a,
	.pagination > li > span {
		color: #47ccde;
	}
	.pagination > li:first-child > a,
	.pagination > li:first-child > span,
	.pagination > li:last-child > a,
	.pagination > li:last-child > span {
		background-color: black;
		color: white;
	}

	.pagination > li:first-child {
		border-left: 0px !important;
		margin-right: 2px;
	}

	.pagination > li:last-child {
		border-left: 0px !important;
		margin-left: 2px;
	}

	.pagination > li:last-child:hover,
	.pagination > li:first-child:hover {
		color: white;
	}

	.add_background {
		-webkit-filter: grayscale(100%);
		-moz-filter: grayscale(100%);
		filter: grayscale(100%);
		transition: all 0.5s ease;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	}

	.add_background:hover {
		-webkit-filter: grayscale(0%);
		-moz-filter: grayscale(0%);
		filter: grayscale(0%);
	}

	.adSM {
		width: 300px;
		height: 150px;
		object-fit: cover;
	}

	.adSQ {
		height: 300px;
		width: 300px;
		object-fit: cover;
	}

	.stock_badge {
		z-index: 1;
		position: absolute;
		margin-top: -20px;
		margin-left: -85px;
		text-align: center;
		padding-top: 28px;
		height: 100px;
		width: 100px;
		background-color: #990d24;
		color: white;
		border-radius: 100%;
	}

	.stock_badge span {
		font-weight: 700;
	}

	@media only screen and (max-width: $breakpoint-sm) {
		.stock_badge {
			margin-top: -5px;
			// margin-left: 250px;
		}
	}

	.v-hidden {
		visibility: hidden;
	}

	.th-comparator {
		font-size: 95% !important;
	}

	.specs-img {
		max-height: 220px;
	}

	.contact_pro_logo {
		height: min-content;
	}

	.view-title {
		margin-bottom: 50px;
		border-bottom: 1px solid black;
	}

	.guide-preview {
		max-width: -webkit-fill-available;
		max-width: -moz-available;
	}

	.submit_column_prices {
		position: relative;
	}

	img.price-col {
		filter: blur(2px);
		max-width: -webkit-fill-available;
		max-width: -moz-available;
	}
	.overlay-vsearch {
		position: absolute;
		top: 10px;
		color: #fff;
	}
}
